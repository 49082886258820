import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    /* --_93Yellow: #fbdd2b; */
    /* --_MaiTaiPink: #e6194e; */
    /* --_HCPurple: #8666ac; */
    --aeriz_blue: #A5D0CC;
    --light_grey: #9D9FA2;
    --silver: #9D9FA2;
    --charcoal: #464547;
    --pink: #F8B5BA;
    --yellow: #FEC46F;
    --rose_grey: #F4EDEB;
    --letter_spacing: .12rem;
  }

  html {
    background-color: white;
    font-size: 62.5%;
    font-smooth: always;
    /* max-width: 100vw; */
    /* overflow-x: hidden; */
  }

  body {
    max-width: 100vw;
    overflow-x: hidden;
    background-color: white;
  }

  p {
    line-height: 2;
  }

  input[type='email']::-internal-autofill-selected {
    background: transparent;
  }

  .logo-main {
    justify-self: center;
  }

  ::selection {
    background:var(--aeriz_blue);
    color:white;
  }
  
  ::-moz-selection {
    background:var(--aeriz_blue);
    color:white;
  }


  .no-pref-selected .radio-element,
  .no-email input#aeriz-email {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
  
  .no-pref-selected-still .radio-element,
  .no-email-still input#aeriz-email {
    animation: shake1 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  .success div.aeriz-stamp {
    animation: successSpin 0.82s cubic-bezier(.36,.07,.19,.97) both;
  }

  @keyframes successSpin {
    0%{}
    50% {
      transform: translateY(2px);
    }
    100% {
      transform: rotate(360deg) translateY(0);
    }
  }

  @keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}


@keyframes shake1 {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

`;

export default GlobalStyles;
