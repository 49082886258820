import { createGlobalStyle } from 'styled-components';

const Typography = createGlobalStyle`

html {
  font-family: 'Montserrat'
}

  h1 {
    font-size: 3rem;
  }

  li {
    font-size: 1.6rem;
    font-family: 'Montserrat';
    line-height: 1.5;
    margin-bottom: 20px;;
  }

  h1,h2,h3,h4,h5,h6 {
    font-family: 'Montserrat';
    font-weight: normal;
    margin: 0;
  }

  p {
    font-size: 1.6rem;
    line-height: 1.5;
  }
  
`;

export default Typography;
