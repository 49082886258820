import React from 'react';
import { Helmet } from 'react-helmet';
// import Nav from './Nav';
import 'normalize.css';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import favicon from '../../static/favicon.ico';

export default function Layout({ children }) {
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <meta charSet="utf-8" />
        <title>Aeriz Newsletter Signup</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;900&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" href={favicon} type="image/x-icon" />
        <meta
          name="description"
          content="Sign up and be the first to know about new products, exclusive merch, and more. We promise to only bother you when we’ve got something really good."
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <GlobalStyles />
      <Typography />
      {/* <Nav /> */}
      {children}
    </>
  );
}
