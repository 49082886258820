// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-storagedump-js": () => import("./../../../src/pages/storagedump.js" /* webpackChunkName: "component---src-pages-storagedump-js" */),
  "component---src-pages-waldos-fest-js": () => import("./../../../src/pages/waldos-fest.js" /* webpackChunkName: "component---src-pages-waldos-fest-js" */),
  "component---src-pages-waldos-fest-kiosk-js": () => import("./../../../src/pages/waldos-fest-kiosk.js" /* webpackChunkName: "component---src-pages-waldos-fest-kiosk-js" */)
}

